import {is101010tv, isProd} from '@/utils';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import * as Sentry from '@sentry/vue';
import {createApp} from 'vue';
import App from './App.vue';
import './assets/icon/brands';
import './assets/icon/regular';
import './assets/icon/solid';
import tenTenTen from './plugins/101010tv/101010tv';
import auth from './plugins/auth/auth';
import configuration from './plugins/config/config';
import eventbus from './plugins/eventbus/eventbus';
import features from './plugins/features/features';
import http from './plugins/http/http';
import translations from './plugins/i18n/i18n';
import modal from './plugins/modal/modal';
import router from './router';
import toSortedShim from 'array.prototype.tosorted';
import replaceAllShim from 'string.prototype.replaceall';

// Ensure replaceAll and toSorted are available
replaceAllShim.shim();
toSortedShim.shim();

const app = createApp(App);

// Initialize sentry
Sentry.init({
  app,
  enabled: isProd(),
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_COMPANY,
  release: import.meta.env.VITE_GIT_HASH,
  logErrors: true,
});

app
  // Application features
  .use(features)

  // Application configuration
  .use(configuration)

  // Event bus
  .use(eventbus)

  // Modals
  .use(modal)

  // Translator
  .use(translations, {
    localeResolver: async (locale: string) => (await import(`../translations/messages.${locale}.yml`)).default,
    defaultLocale: 'nl',
    supportedLocales: ['nl', 'en'],
  })

  // Api
  .use(http)

  // Auth
  .use(auth)

  // Router
  .use(router)

  // Font awesome
  .component('font-awesome-icon', FontAwesomeIcon);

if (is101010tv()) {
  app.use(tenTenTen);
}

// Mount app
app.mount('#app');
