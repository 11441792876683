<template>
  <template v-if="!state.appLoading">
    <Navbar/>
    <Sidebar/>
    <div class="content">
      <router-view v-slot="{Component}">
        <keep-alive
          include="HomePage,ApplyPage"
          max="10">
          <component :is="Component"/>
        </keep-alive>
      </router-view>
    </div>
    <div class="bg-container bg-left"/>
    <div class="bg-container bg-right"/>
  </template>

  <div
    class="overlay-loader"
    :class="{'show': showOverlay}">
    <div class="text-theme text-center h-100 d-flex flex-column justify-content-center">
      <div>
        <SimpleIcon
          icon="circle-notch"
          size="4x"
          :spin="showOverlay"/>
      </div>
    </div>
  </div>

  <ModalContainer/>
</template>

<script lang="ts">
import {PageBusyEvent, PageDoneEvent, PageTitleEvent} from '@/events';
import Navbar from '@components/layout/Navbar.vue';
import Sidebar from '@components/layout/Sidebar.vue';
import SimpleIcon from '@components/SimpleIcon.vue';
import {type AppRoles} from '@plugins/auth/AppRoles';
import {useAuthenticator} from '@plugins/auth/auth';
import {useConfiguration} from '@plugins/config/config';
import {useEventbus} from '@plugins/eventbus/eventbus';
import {useHttp} from '@plugins/http/http';
import {getCurrentLocale, loadInitialLocale, useTranslator} from '@plugins/i18n/i18n';
import ModalContainer from '@plugins/modal/components/ModalContainer.vue';
import installRules from '@plugins/validation/rules';
import {computed, defineComponent, reactive, ref, watchEffect} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {RouteLoaded, RouteLoading} from './router';

export default defineComponent({
  name: 'App',
  components: {ModalContainer, Navbar, Sidebar, SimpleIcon},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const currentLocale = getCurrentLocale();
    const translator = useTranslator();
    const state = reactive({
      appLoading: true,
      routeLoading: false,
      pageBusy: false,
    });
    const pageTitle = ref<string | null>('loading');

    const eventBus = useEventbus();
    eventBus.on(RouteLoading, () => {
      state.routeLoading = true;
    });
    eventBus.on(RouteLoaded, () => {
      state.routeLoading = false;
    });

    eventBus.on(PageBusyEvent, () => {
      state.pageBusy = true;
    });
    eventBus.on(PageDoneEvent, () => {
      state.pageBusy = false;
    });

    eventBus.on(PageTitleEvent, (newTitle?: string) => {
      pageTitle.value = newTitle ?? null;
    });

    const dependencies = [
      // Load translation component initial locale
      loadInitialLocale(),

      // Load the application configuration
      useConfiguration().loadConfiguration(useHttp()),
    ];

    const authenticator = useAuthenticator();
    Promise.all(dependencies).then(() => {
      // Update the document title based on the events
      watchEffect(() => {
        let newTitle = translator.translate('_.title', {
          company: import.meta.env.VITE_COMPANY,
        }, currentLocale.value);
        if (pageTitle.value) {
          newTitle += ' | ' + translator.translate(pageTitle.value, undefined, currentLocale.value);
        }

        document.title = newTitle;
      });

      // Install validation rules
      installRules();

      // Load authentication and check current route
      authenticator.loadCurrentAuth().finally(async () => {
        // Wait for the router to also be ready
        await router.isReady();

        // Test current route
        if (!authenticator.isAuthenticated.value && (!route.meta.public || route.meta.role)) {
          await router.replace({name: 'homepage'});
        } else if (route.meta.role && !authenticator.hasRole(route.meta.role as AppRoles)) {
          await router.replace({name: 'homepage'});
        }

        // App finished loading
        state.appLoading = false;
      });
    });

    return {
      state,
      showOverlay: computed(() => Object.values(state).some((v) => v)),
    };
  },
});
</script>

<style lang="scss">
@import 'src/assets/css/variables';
@import 'bootstrap/scss/bootstrap';
@import 'src/assets/css/mixins/position';
@import 'src/assets/css/components/all';

#app {
  > .content {
    position: relative;
    min-height: calc(100vh - #{$navbar-height} - 1px);
    margin-top: calc(#{$navbar-height} + 1px);
    padding: 0 map-get($spacers, 3) map-get($spacers, 3);

    @include media-breakpoint-up(sm) {
      padding-left: calc(#{$sidebar-collapsed-width} + 1px + #{map-get($spacers, 3)});
    }
  }

  @if ($company == 'a2b') {
    > .bg-container {
      @include absolute-full-cover(fixed);
      z-index: -1;
      pointer-events: none;
      background-size: contain;
      background-repeat: no-repeat;

      &.bg-left {
        background-position: bottom left;
        background-image: url('assets/background/a2b/bg-left.png');
      }

      &.bg-right {
        background-position: bottom right;
        background-image: url('assets/background/a2b/bg-right.png');
      }
    }
  }

  .overlay-loader {
    @include absolute-full-cover(fixed);
    z-index: 2000;
    opacity: 0;
    background-color: transparentize($black, 0.8);
    backdrop-filter: blur(2px);
    pointer-events: none;

    @include transition(opacity $default-animation);

    &.show {
      pointer-events: all;
      cursor: progress;
      opacity: 1;
    }
  }
}
</style>
