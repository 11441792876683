<template>
  <nav
    class="navbar fixed-top ps-sm-0 flex-nowrap"
    :class="isA2B ? 'navbar-light' : 'navbar-dark'">
    <router-link
      class="logo-md navbar-brand d-none d-sm-block"
      to="/">
      <div class="icon">
        <img
          v-if="isA2B"
          alt="A2B"
          src="@assets/brand/a2b/icon.svg">
        <img
          v-else-if="isDC1"
          alt="DC1"
          src="@assets/brand/dc1/icon.svg">
        <img
          v-else-if="is101010tv"
          alt="101010.tv"
          class="p-2"
          src="@assets/brand/101010tv/icon.svg">
      </div>
      <div class="divider">
        <div v-if="isA2B"/>
      </div>
      <div class="text">
        <img
          v-if="isA2B"
          alt="A2B"
          src="@assets/brand/a2b/text.svg">
        <img
          v-if="isDC1"
          alt="DC1"
          class="ps-4 p-2"
          src="@assets/brand/dc1/text.svg">
      </div>
    </router-link>

    <div class="menu-button d-sm-none">
      <button
        class="navbar-toggler"
        type="button"
        @click="eventbus.emit('toggle-sidebar')">
        <span class="navbar-toggler-icon"/>
      </button>
    </div>
    <div
      class="logo-sm flex-fill"
      :class="is101010tv ? '' : 'd-sm-none'">
      <router-link
        class="navbar-brand m-0"
        to="/">
        <img
          v-if="isA2B"
          alt="A2B"
          src="@assets/brand/a2b/logo.svg">
        <img
          v-if="isDC1"
          alt="DC1"
          src="@assets/brand/dc1/logo.svg">
        <template v-if="is101010tv">
          <img
            alt="101010.TV"
            class="p-2 d-none d-sm-inline"
            src="@assets/brand/101010tv/logo.svg">
          <img
            alt="101010.TV"
            class="d-sm-none"
            src="@assets/brand/101010tv/1x2.svg">
        </template>
      </router-link>
    </div>
    <div class="account-button">
      <div class="nav-item dropdown">
        <a
          id="accountDropdown"
          aria-expanded="false"
          aria-haspopup="true"
          class="dropdown-toggle"
          :class="is101010tv ? 'text-white' : 'text-theme'"
          data-bs-toggle="dropdown"
          href="#"
          role="button">
          <SimpleIcon :icon="isAuthenticated ? 'user' : 'globe'"/>
        </a>
        <div
          aria-labelledby="accountDropdown"
          class="dropdown-menu dropdown-menu-end">
          <template v-if="account !== null">
            <router-link
              class="dropdown-item"
              :to="{name: 'my-company'}">
              <SimpleIcon icon="building"/>
              {{ account.company }}
            </router-link>
            <div class="dropdown-divider"/>
          </template>
          <div
            class="dropdown-item"
            @click.stop="setLocale?.('nl')">
            <Translated
              forced-locale="nl"
              msg="locale.dutch"/>
            <SimpleIcon
              v-show="locale === 'nl'"
              icon="check"/>
            <SimpleIcon
              v-show="localesLoading?.includes('nl')"
              icon="circle-notch"
              spin/>
          </div>
          <div
            class="dropdown-item"
            @click.stop="setLocale?.('en')">
            <Translated
              forced-locale="en"
              msg="locale.english"/>
            <SimpleIcon
              v-show="locale === 'en'"
              icon="check"/>
            <SimpleIcon
              v-show="localesLoading?.includes('en')"
              icon="circle-notch"
              spin/>
          </div>
          <template v-if="isAuthenticated">
            <div class="dropdown-divider"/>
            <a
              v-if="externalAuthBased"
              class="dropdown-item"
              :href="ssoManageUrl"
              rel="noopener noreferrer"
              target="_blank">
              <SimpleIcon icon="user-shield"/>
              <Translated msg="user-management.title.manage-own-account"/>
            </a>
            <router-link
              v-else
              class="dropdown-item"
              :to="{name: 'change-password'}">
              <SimpleIcon icon="user-shield"/>
              <Translated msg="user-management.title.change-password"/>
            </router-link>
            <router-link
              class="dropdown-item"
              :to="{name: 'account-api-tokens'}">
              <SimpleIcon icon="key"/>
              <Translated msg="user-management.title.manage-api-tokens"/>
            </router-link>
            <div class="dropdown-divider"/>
            <router-link
              class="dropdown-item"
              :to="{name: 'logout'}">
              <SimpleIcon icon="sign-out-alt"/>
              <Translated msg="auth.logout"/>
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import {is101010tv, isA2B, isDC1} from '@/utils';
import {useAuthenticator, useCurrentAccount, useIsAuthenticated} from '@plugins/auth/auth';
import {useConfiguration} from '@plugins/config/config';
import {useEventbus} from '@plugins/eventbus/eventbus';
import Translated from '@plugins/i18n/components/Translated.vue';
import {getCurrentLocale, useLocalesLoading, useSetLocale} from '@plugins/i18n/i18n';
import BsDropdown from 'bootstrap/js/dist/dropdown';
import {defineComponent, onMounted} from 'vue';
import SimpleIcon from '../SimpleIcon.vue';

export default defineComponent({
  components: {Translated, SimpleIcon},
  setup() {
    const authenticator = useAuthenticator();
    const configuration = useConfiguration();

    onMounted(() => {
      new BsDropdown('#accountDropdown'); // eslint-disable-line no-new
    });

    return {
      account: useCurrentAccount(),
      isAuthenticated: useIsAuthenticated(),
      locale: getCurrentLocale(),
      localesLoading: useLocalesLoading(),
      setLocale: useSetLocale(),
      eventbus: useEventbus(),
      isA2B: isA2B(),
      isDC1: isDC1(),
      is101010tv: is101010tv(),
      externalAuthBased: authenticator.externalAuthBased,
      ssoManageUrl: configuration.getSsoManageUrl(),
    };
  },
});
</script>

<style scoped lang="scss">
@import 'src/assets/css/variables';
@import 'src/assets/css/mixins/position';

.navbar {
  height: $navbar-height;
  z-index: 500;

  @if ($company == 'a2b') {
    background-color: $white;
  }
  @if ($company == 'dc1') {
    background-color: $black;
  }
  @if ($company == '101010tv') {
    background-color: $purple-dark;
  }

  .logo-md {
    position: relative;
    padding: 0;
    height: 100%;

    * {
      height: 100%;
    }

    .icon {
      @include absolute-full-cover();
      text-align: center;
    }

    .divider {
      width: $sidebar-collapsed-width;
      padding: ($navbar-height * 0.125 * 0.5) 0;

      > div {
        width: 100%;
        border-right: $sidebar-border;
      }
    }

    .text {
      @include absolute-full-cover();
      left: $sidebar-collapsed-width;
    }
  }

  .menu-button, .account-button {
    flex-basis: 4rem;
    @include media-breakpoint-down(sm) {
      flex-basis: 6rem;
    }
  }

  .logo-sm {
    height: 100%;
    text-align: center;

    .navbar-brand {
      height: 100%;
      width: 40vw;

      img {
        height: 100%;
        max-width: 100%;
      }
    }
  }

  .account-button {
    text-align: right;

    .dropdown-item {
      cursor: pointer;
      background: none;

      &:hover {
        color: $theme;
      }
    }
  }
}
</style>
