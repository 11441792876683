<template>
  <li class="nav-item">
    <router-link
      active-class="active"
      class="nav-link"
      :to="to"
      @click="toggleSidebar()">
      <div class="menu-item d-flex">
        <div>
          <SimpleIcon :icon="icon"/>
        </div>
        <div class="label">
          <Translated
            :msg="label"
            :props="labelProps"/>
        </div>
      </div>
    </router-link>
  </li>
</template>

<script lang="ts">
import {useEventbus} from '@plugins/eventbus/eventbus';
import Translated from '@plugins/i18n/components/Translated.vue';
import {defineComponent} from 'vue';
import SimpleIcon from '../SimpleIcon.vue';

export default defineComponent({
  components: {Translated, SimpleIcon},
  props: {
    icon: {
      type: [String],
      required: true,
    },
    label: {
      type: [String],
      required: true
    },
    labelProps: Object,
    to: {
      type: [String, Object],
      required: true,
    },
  },
  setup: () => {
    const bus = useEventbus();

    return {
      toggleSidebar: () => bus.emit('close-sidebar'),
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/assets/css/variables';

.nav-item {
  max-width: 100%;
  white-space: nowrap;

  .nav-link {
    color: $black;

    &.active {
      color: $theme;
      background-color: transparent;
    }

    &:hover {
      color: $theme;
    }

    .menu-item {
      max-width: 100%;
      white-space: nowrap;

      @include transition(color $default-animation);

      .label {
        padding-left: $spacer * 0.5;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
</style>
